exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dentalprofessionals-js": () => import("./../../../src/pages/dentalprofessionals.js" /* webpackChunkName: "component---src-pages-dentalprofessionals-js" */),
  "component---src-pages-educators-js": () => import("./../../../src/pages/educators.js" /* webpackChunkName: "component---src-pages-educators-js" */),
  "component---src-pages-foradults-js": () => import("./../../../src/pages/foradults.js" /* webpackChunkName: "component---src-pages-foradults-js" */),
  "component---src-pages-forkids-js": () => import("./../../../src/pages/forkids.js" /* webpackChunkName: "component---src-pages-forkids-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-underconstruction-js": () => import("./../../../src/pages/underconstruction.js" /* webpackChunkName: "component---src-pages-underconstruction-js" */)
}

